// CustomCursor.js
// CustomCursor.js
import React, { useState, useEffect } from 'react';


const CustomCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const updateCursorPosition = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', updateCursorPosition);

    return () => {
      window.removeEventListener('mousemove', updateCursorPosition);
    };
  }, []);

  return (
    <>
      <div className="custom-cursor" style={{ left: `${position.x}px`, top: `${position.y}px` }}></div>
      <div className="tail"></div>
    </>
  );
};

export default CustomCursor;
